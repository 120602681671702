import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import glabol from "../../assets/images/glabol-logo.png";
import "./Login.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hook";
import { login } from "../../Reducers/authSlice";
import { userLogin, userLogin2 } from "../../Reducers/authSlice";


const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const auth = useAppSelector((state: any) => state.auth);
  const [mobileError, setMobileError] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [otpValue, setOtpValue] = useState<string>("");
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [otpReceived, setOtpReceived] = useState(false);
  const [otpSentTime, setOtpSentTime] = useState<number | null>(null);

  const handleMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericValue = input.replace(/\D/g, "");
    if (numericValue.length <= 10) {
      setMobile(numericValue);
    }
  };
  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpValue(e.target.value);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isTimerActive) {
      interval = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) {
            setIsTimerActive(false);
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      setRemainingTime(60);
    }
    return () => clearInterval(interval);
  }, [isTimerActive]);

  const handleApi = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (mobile.length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Please enter a 10-digit mobile number",
      });
      return;
    }
    const body: any = {
      mobile: mobile,
    };
    dispatch(userLogin(body))
      .unwrap()
      .then((res: any) => {
        if (res?.error === "Mobile number not registered.") {
          return;
        }

        Swal.fire({
          icon: "success",
          title: "OTP Sent Successfully",
          timer :5000,

        });

        setIsTimerActive(true);
        setOtpReceived(true);
        setOtpSentTime(Date.now());
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Mobile number not registered",
        });
      });
  };


  const handleverify = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (otpSentTime && Date.now() - otpSentTime > 60000) {
      Swal.fire({
        icon: "error",
        title: "Invalid OTP",
        width: 600,
        padding: "3em",
      });
      return;
    }
    const body: any = {
      mobile: mobile,
      otp: otpValue,
    };
    dispatch(userLogin2(body))
      .unwrap()
      .then((res: any) => {
        if (res?.success && res.getUserData?._id) {
          localStorage.setItem("userData", JSON.stringify(res.getUserData));
          dispatch(login());
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (redirectUrl) {

            navigate(redirectUrl);
                } else {

            navigate("/");
          }

          Swal.fire({
            icon: "success",
            title: "Login Successfully",
            timer: 5000,
          });
        }
      })
      .catch((error: any) => {
        if (error.message === "Invalid OTP") {
        } else {
          Swal.fire({
            icon: "error",
            title: "Invalid OTP  Please try again",
          });
        }
      });
  };
  return (
    <>
      <div className=" ">
        <div className="img-fluid bg-image background1">
          <div className="mainform ">
            <div className="bigdivform">
              <h6 className="wel">
                WELCOME TO
                <h1 style={{ textAlign: "center" }}>
                  <img
                    src={glabol}
                    className="img-fluid  glabol1"
                    alt="Responsive Image"
                  />
                </h1>
              </h6>
              <h6 className="logincontent">
                Log in to our travel portal and unlock a world
                <br />
                of possibilities.
              </h6>

              <Form>
                <Form.Group className="mb-3 " controlId="formGroupEmail">
                  <Form.Label className="sendlable">
                    {otpReceived && !isTimerActive ? (
                      <Button
                        variant="link"
                        className="sendotp "
                        onClick={handleApi}
                        disabled={!mobile}
                      >
                        Resend OTP
                      </Button>
                    ) : (
                      <Button
                        variant="link"
                        className="sendotp "
                        onClick={handleApi}
                        disabled={!mobile || isTimerActive}
                      >
                        {isTimerActive
                          ? `Resend OTP (${remainingTime}s)`
                          : "Send OTP"}
                      </Button>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your mobile number"
                    className="your-input-class"
                    value={mobile}
                    disabled={mobileError !== ""}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                    }}
                    onChange={handleMobile}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        handleApi(e);
                      }
                    }}
                    autoComplete="off"
                  />
                  {mobileError && (
                    <p className="text-danger mt-2">{mobileError}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <Form.Control
                    type="text"
                    value={otpValue}
                    onChange={handleOtpChange}
                    placeholder="Enter OTP"
                    maxLength={4}
                    title="Please enter a 4-digit OTP"
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        handleverify(e);
                      }
                    }}
                    className="your-input-class"
                    autoComplete="off"
                  />
                </Form.Group>

                <h1 style={{ textAlign: "center" }}>
                  {" "}
                  <Button
                    variant="link"
                    className="verifybtn2"
                    onClick={handleverify}
                    disabled={mobile.length !== 10 || !otpValue}
                  >
                    Verify OTP
                  </Button>
                </h1>

                <h2 className="lastpara">
                  Don’t have an account ?
                  <Link to={"/signup"} className="lastlink">
                    {" "}
                    Sign Up
                  </Link>
                </h2>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
