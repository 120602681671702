import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./Booking.css";
import Cancelled from "../../assets/images/Cancelled.png";
import Card from "react-bootstrap/Card";
import { LuRefreshCw } from "react-icons/lu";
import { BsCheckLg } from "react-icons/bs";
import axios from "axios";
import { Loader } from "../loader/Loader";
import GeneralDetails from "../details/generaldetails/GeneralDetails";
interface Booking {
  travel_boarding_point: any;
  _id: string;
  tour_id: {
    title: string;
    tour_name: string;
  };
  travel_date: string;
  payment_status: string;
}

interface UserContextData {
  user_id: string;
}

const Booking: React.FC = () => {
  const context = useOutletContext<UserContextData>() || {};
  const { user_id } = context;
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [load, setLoad] = useState<boolean>(true);

  let useData: any = localStorage.getItem("userData");
  let id = JSON.parse(useData)?._id;

  const navigate = useNavigate();

  useEffect(() => {
    let useData: any = localStorage.getItem("userData");
    if (useData) {
      let id = JSON.parse(useData)?._id;
    }
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    setLoad(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user-all-checkout-tourNew`,
        {
          user_id: id,
        }
      );

      if (response.data.success) {
        const userData = response.data.getData;
      
        setBookings(userData);
        localStorage.setItem(
          "BookingID",
          JSON.stringify(response.data.getData)
        );
        ViewMore(userData);
      } else {
        console.log(
          "API giving false data in customer profile ",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
    setLoad(false);
  };
  const formatDateRange = (dateRange: string) => {
    const [startDate, endDate] = dateRange.split("-");

    const start = new Date(startDate);
    const end = new Date(endDate);

    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const formattedStartDate = `${
      monthNames[start.getMonth()]
    } ${start.getDate()}, ${start.getFullYear()}`;

    const formattedEndDate = `${
      monthNames[end.getMonth()]
    } ${end.getDate()}, ${end.getFullYear()}`;

    return `${formattedStartDate} - ${formattedEndDate}`;
  };
  const dateRange = "booking.travel_date";
  const formattedDateRange = formatDateRange(dateRange);
  const ViewMore = (booking: any) => {
               return <GeneralDetails tripDetails={booking} />;
  }; 
const handleViewMore = (booking:any) => {
  ViewMore(booking);
  navigate("/generaldetails", { state: { id: booking._id } });
};
  return (
    <>
      {load ? <Loader /> : ""}
      <div>
        <Card className="booking">
          <Card.Body>
            <Card.Text className="customer-border">Booking History</Card.Text>
          </Card.Body>
        </Card>
        {bookings.map((booking, index) => (
          <Card key={booking?._id} className="headcard">
            <Card.Body>
              <Card.Text>
                <Row className="listtext">
                  <Col className="for-customclass-forview col-lg-2 col-4">
                    {booking?.tour_id?.title}
                  </Col>
                  <Col className="for-customclass-forview col-lg-4 col-8">
                    {formatDateRange(booking.travel_date)}
                  </Col>
                  <Col className="for-customclass-forview col-lg-2 col-5 custom-for-booking">
                    {booking.payment_status}
                  </Col>
                  <Col className="for-customclass-forview col-2 custom-for-booking">
                    {booking.payment_status === "Complete" ? (
                      <BsCheckLg className="bookicon3" />
                    ) : booking.payment_status === "Cancelled" ? (
                      <img src={Cancelled} alt="Cancelled" />
                    ) : (
                      <LuRefreshCw className="bookicon4 for-partial-payment-color" />
                    )}
                  </Col>
                  <Col className="col-lg-2 col-5 custom-for-booking ">
                    {" "}
                    {/* <Link
                      to="#"
                      className="view-more-text col-4 col-md-2 date for-customclass-forview"
                    > */}
                    <span
                      className="view-more-text col-4 col-md-2 date for-customclass-forview"
                      onClick={() => handleViewMore(booking)}
                    >
                      View More
                    </span>
                    {/* <GeneralDetails values={booking}/> */}
                    {/* </Link> */}
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </>
  );
};

export default Booking;
