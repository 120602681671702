import React, { useState, useEffect } from "react";
import "./traveller.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hook";
import Swal from "sweetalert2";

interface Traveller {
  Document_Front: { src: string; fileName: string; orgname: string }[];
  Document_Back?: { src: string; fileName: string; orgname: string }[];
  name: string;
  DOB: string;
  age: string;
  mobile: string;
  email: string;
  country: string;
  state: string;
  pincode: string;
  city: string;
  Idproof: string;
  roomSharing: string;
  boarding_point: string;
  IdNumber: string;
  isOpen: boolean;
  packageType: string;
}
const Travellar = () => {
  const tourDetails = useAppSelector(
    (state: any) => state?.auth?.travellerDetails?.value
  );

  const [travellers, setTravellers] = useState<Traveller[]>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string[]>(
    Array(travellers.length).fill("")
  );
  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);
  const [countries, setCountries] = useState<any[]>([]);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [states, setStates] = useState<any[]>([]);
  const [packageTypes, setPackageTypes] = useState<string[]>([]);
  const [roomsharing, setRoomsharing] = useState<string[]>([]);
  const [file, setFile] = useState<any>();
  const [file2, setFile2] = useState<any>();
  const [bookingObject, setBookingObject] = useState<any>(null);
  const [travelBoardingPoint, setTravelBoardingPoint] = useState("");
  const [load, setLoad] = useState<boolean>(true);
  const [boardingData, setBoardingData] = useState<string | null>(null);
  const [travllerPackagetype, setTravllerPackageType] = useState<
    { packType: string; packPrice: string; persons: number }[]
  >([]);
  const [travllerRoomSharing, setTravllerRoomSharing] = useState<
    { roomType: string; roomPrice: string; persons: number }[]
  >([]);
  const navigate = useNavigate();

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const newTravellers = [...travellers];
    newTravellers[index] = {
      ...newTravellers[index],
      [name]: value,
    };

    if (name === "DOB") {
      const dob = new Date(value);
      const today = new Date();
      const age = (today.getFullYear() - dob.getFullYear()).toString();
      newTravellers[index].age = age;
    }
    newTravellers[index].boarding_point = tourDetails?.tour_id || "";
    setTravellers(newTravellers);
  };

  const addTravellersToArray = (count: number) => {
    const obj: Traveller = {
      Document_Front: [{ src: "", fileName: "", orgname: "" }],
      Document_Back: [{ src: "", fileName: "", orgname: "" }],
      name: "",
      mobile: "",
      email: "",
      age: "",
      DOB: "",
      country: "",
      city: "",
      state: "",
      Idproof: "",
      IdNumber: "",
      boarding_point: tourDetails?.tour_id || "",
      roomSharing: "",
      packageType: "",
      pincode: "",
      isOpen: false,
    };
    const newArray: Traveller[] = [];
    for (let i = 0; i < count; i++) {
      newArray.push({ ...obj });
    }
    setTravellers(newArray);
  };

  useEffect(() => {
    const travellerCountString = localStorage.getItem("TravellerCounts");
    const travellerCount = travellerCountString
      ? parseInt(travellerCountString)
      : 0;
    addTravellersToArray(travellerCount);
  }, []);

  useEffect(() => {
    const loadCheckoutData = async () => {
      const checkoutResponse = localStorage.getItem("checkoutResponse");
      let objId = "";
      if (checkoutResponse) {
        const parsedCheckoutResponse = JSON.parse(checkoutResponse);
        objId = parsedCheckoutResponse.objId;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/get-appliedTourById`,
          { id: objId }
        );

        setBoardingData(response.data.getData.travel_boarding_point.boarding);
        setTravllerPackageType(response.data.getData.travel_packageType_data);
        setTravllerRoomSharing(response.data.getData.travel_roomSharing_data);
      } catch (err) {
        // setError('Failed to load checkout data');
        console.error(err);
      } finally {
        // setLoading(false);
      }
    };

    loadCheckoutData();
  }, []);

  const toggleAccordion = (index: number) => {
    const newTraveller = travellers.map((traveller, i) => {
      if (index === i) {
        return { ...traveller, isOpen: !traveller.isOpen };
      } else {
        return { ...traveller, isOpen: false };
      }
    });
    setTravellers(newTraveller);
  };

  const handleIdChange = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    setTravellers((prevState) => {
      const updatedTravellers = [...prevState];
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        Idproof: value,
      };
      return updatedTravellers;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const checkoutResponse = localStorage.getItem("checkoutResponse");
      let objId = "";
      if (checkoutResponse) {
        const parsedCheckoutResponse = JSON.parse(checkoutResponse);
        objId = parsedCheckoutResponse.objId;
      }
      setLoad(true);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/userTourDetails/alltourUserDetailsById?applyTour_id=${objId}`
        );
        if (response.data.success) {
          setTravellers(response.data.data[0].travelers);
        } else {
          console.error("Failed to fetch data:", response.data.error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoad(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (index: number) => {
    if (travellers[index].name.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please Enter Full Name.",
      });
      return;
    }

    if (travellers[index].mobile.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please Enter Mobile Number.",
      });
      return;
    }
    if (travellers[index].pincode.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please Enter Pincode.",
      });
      return;
    }
    const mobileNumberPattern = /^[0-9]{10}$/;
    if (!mobileNumberPattern.test(travellers[index].mobile)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Mobile Number",
        text: "Please enter a valid Mobile Number (10 digits).",
      });
      return;
    }

    if (travellers[index].email.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please Enter Email Address.",
      });
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(travellers[index].email)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Email",
        text: "Please enter a valid Email address.",
      });
      return;
    }
    if (travellers[index].country.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please fill in the country field.",
      });
      return;
    }
    if (travellers[index].state.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please fill in the state field.",
      });
      return;
    }
    if (travellers[index].IdNumber.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please fill in the Id Number field.",
      });
      return;
    }
    if (travellers[index].Idproof.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please fill in the Id Proof field.",
      });
      return;
    }
    // if (travellers[index].packageType.trim() === "") {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Missing Information",
    //     text: "Please fill in the Package Type field.",
    //   });
    //   return;
    // }
    if (travellers[index].roomSharing.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please fill in the Room Sharing field.",
      });
      return;
    }

    if (travellers[index].DOB.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please provide the Date of Birth for this traveler.",
      });
      return;
    }

    const Document_Front: any = travellers[index].Document_Front;


    if (!file) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please upload the front document.",
      });
      return;
    }

    const Document_Back: any = travellers[index].Document_Back; // Corrected this line

    if (!file2) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        text: "Please upload the back document.",
      });
      return;
    }

    const checkoutResponse = localStorage.getItem("checkoutResponse");
    let objId = "";
    if (checkoutResponse) {
      const parsedCheckoutResponse = JSON.parse(checkoutResponse);
      objId = parsedCheckoutResponse.objId;
    }
    travellers[index].Document_Back = file;
    travellers[index].Document_Front = file2;
    const updatedTravellers: any = travellers.map((traveller) => ({
      ...traveller,
      boarding_point: boardingData || "",
    }));

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/setUserTourDetails?applyTour_id=${objId}`,
        { travelers: updatedTravellers }
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Traveller Added Successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Submission Failed",
          text:
            response.data.error || "An error occurred while submitting data.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting data.",
      });
      console.error("Error submitting data:", error);
    }
  };

  const handleSkip = async () => {
    const checkoutResponse = localStorage.getItem("checkoutResponse");
    let objId = "";
    if (checkoutResponse) {
      const parsedCheckoutResponse = JSON.parse(checkoutResponse);
      objId = parsedCheckoutResponse.objId;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/setUserTourDetails?applyTour_id=${objId}`,
        { travelers: travellers }
      );

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Traveller Added Successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Submission Failed",
          text:
            response.data.error || "An error occurred while submitting data.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting data.",
      });
      console.error("Error submitting data:", error);
    }
    navigate("/");
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getAll_countriesList`
        );
        setCountries(response.data.Data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchStatesByCountryCode = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getStateByCountryCode`,
          { countryShortCode: selectedCountryCode }
        );
        setStates(response.data.getData);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    if (selectedCountryCode) {
      fetchStatesByCountryCode();
    }
  }, [selectedCountryCode]);

  const handleCountryChange = (event: any, index: number) => {
    const selectedValue = event.target.value;
    const selectedLabel = event.target.options[event.target.selectedIndex].text;

    setSelectedCountryCode((prev) => {
      const updatedCodes = [...prev];
      updatedCodes[index] = selectedValue;
      return updatedCodes;
    });

    setTravellers((prev) => {
      const updatedTravellers = [...prev];
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        country: selectedLabel,
      };
      return updatedTravellers;
    });
  };

  const handleStateChange = (event: any, index: number) => {
    const selectedValue = event.target.value;
    const selectedLabel = event.target.options[event.target.selectedIndex].text;

    setTravellers((prev) => {
      const updatedTravellers = [...prev];
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        state: selectedLabel,
      };
      return updatedTravellers;
    });
    setSelectedStateCode(selectedValue);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // setFile("");
    if (event.target.files && event.target.files.length > 0) {
      const form = new FormData();
      form.append("uploadFile", event.target.files[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload-file`,
        form
      );
      setFile(response.data.file_data[0]);
    }
  };

  const handleFileChange2 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // setFile2("");
    if (event.target.files && event.target.files.length > 0) {
      const form = new FormData();
      form.append("uploadFile", event.target.files[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload-file`,
        form
      );
      setFile2(response.data.file_data[0]);
    }
  };

  return (
    <div>
      <div className="container mt-3">
        <p className="m_heading">
          Your Payment is successful, to confirm your bookings kindly fill the
          travelers details below :
        </p>
      </div>

      {travellers?.map((ele: Traveller, index) => (
        <div key={index} className="container traveller-form-size mb-4 ">
          <div
            className="accordion accordion-style accordion-flush mt-4"
            id={`accordionFlushExample${index}`}
          >
            <div className="accordion-item">
              <h1
                className={`traveller-H accordion-header accordion-button ${
                  ele.isOpen ? "" : "collapsed"
                }`}
                data-bs-toggle="collapse"
                data-bs-target={`#flush-collapse${index}`}
                aria-expanded={ele.isOpen ? "true" : "false"}
                aria-controls={`flush-collapse${index}`}
                onClick={() => toggleAccordion(index)}
              >
                Traveller {index + 1} &nbsp;
                {ele.name ? <span> - {ele.name}</span> : "Details"}
              </h1>

              <div
                id={`flush-collapse${index}`}
                className={`accordion-collapse collapse ${
                  ele.isOpen ? "show" : ""
                }`}
                data-bs-parent={`#accordionFlushExample${index}`}
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-12 ">
                      <form className="row g-3">
                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`name-${index}`}
                                className="form-label travellar-lable"
                              >
                                Full Name:
                              </label>
                              <input
                                type="text"
                                id="name"
                                className="form-control travellar-input c-b"
                                name="name"
                                value={ele.name}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Enter Your Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`dob-${index}`}
                                className="form-label travellar-lable"
                              >
                                {" "}
                                Date Of Birth
                              </label>
                              <input
                                type="date"
                                id={`dob-${index}`}
                                className="form-control travellar-input c-b"
                                name="DOB"
                                value={ele.DOB}
                                onChange={(e) => handleInputChange(index, e)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`age-${index}`}
                                className="form-label travellar-lable"
                              >
                                Age
                              </label>
                              <input
                                type="text"
                                id={`age-${index}`}
                                className="form-control travellar-input c-b"
                                name="age"
                                value={ele.age}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Calculated Age"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`mobile-${index}`}
                                className="form-label travellar-lable"
                              >
                                Mobile Number
                              </label>
                              <input
                                type="tel"
                                id={`mobile-${index}`}
                                inputMode="numeric"
                                className="form-control travellar-input c-b"
                                name="mobile"
                                value={ele.mobile}
                                maxLength={10}
                                onChange={(e) => handleInputChange(index, e)}
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                placeholder="Ex. 9876543210"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`email-${index}`}
                                className="form-label travellar-lable"
                              >
                                Email Id
                              </label>
                              <input
                                type="email"
                                className="form-control travellar-input c-b"
                                id={`email-${index}`}
                                name="email"
                                value={ele.email}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="john321@gmail.com"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`country-${index}`}
                                className="form-label travellar-lable"
                              >
                                Country
                              </label>
                              <select
                                id={`country-${index}`}
                                className="form-select travellar-input"
                                name="country"
                                value={selectedCountryCode[index]}
                                onChange={(e) => handleCountryChange(e, index)}
                              >
                                <option value="">Select a country</option>
                                {countries.map((country) => (
                                  <option
                                    key={country.id}
                                    value={country.countryShortCode}
                                  >
                                    {country.countryName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`state-${index}`}
                                className="form-label travellar-lable"
                              >
                                State
                              </label>

                              <select
                                id={`state-${index}`}
                                className="form-select travellar-input"
                                name="state"
                                value={travellers[index].state}
                                onChange={(e) => handleStateChange(e, index)}
                              >
                                <option value="">Select a State</option>
                                {states?.map((state) => (
                                  <option
                                    key={state.shortCode}
                                    value={state.stateCode}
                                  >
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`pincode-${index}`}
                                className="form-label travellar-lable"
                              >
                                Pincode
                              </label>
                              <input
                                type="text"
                                id={`pincode-${index}`}
                                className="form-control travellar-input c-b"
                                name="pincode"
                                value={ele.pincode}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Ex. 452014"
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                maxLength={6}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`city-${index}`}
                                className="form-label travellar-lable"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                id={`city-${index}`}
                                className="form-control travellar-input c-b"
                                name="city"
                                value={ele.city}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="City"
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="row g-3">
                            <div key={index} className="col-md-12">
                              <label
                                htmlFor={`Idproof-${index}`}
                                className="form-label travellar-lable"
                              >
                                ID Proof
                              </label>
                              <select
                                id={`Idproof-${index}`}
                                className="form-select travellar-input"
                                name="Idproof"
                                value={ele.Idproof}
                                onChange={(e) => handleIdChange(index, e)}
                              >
                                <option>Select ID Proof</option>
                                <option value="Adharcard">Aadhar Card</option>
                                <option value="pancard">Pan card</option>
                                <option value="drivinglicence">
                                  Driving Licence
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="packageType"
                                className="form-label travellar-lable"
                              >
                                Package Type
                              </label>
                              <select
                                name="packageType"
                                className="form-select travellar-input"
                                onChange={(e) => handleInputChange(0, e)}
                              >
                                <option value="">Select Package Type</option>
                                {travllerPackagetype
                                  .filter(
                                    (packageType) => packageType.persons !== 0
                                  )
                                  .map((packageType, idx) => (
                                    <option
                                      key={idx}
                                      value={packageType.packType}
                                    >
                                      {packageType.packType}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor={`roomSharing-${index}`}
                                className="form-label travellar-lable"
                              >
                                Room Sharing
                              </label>
                              <select
                                name="roomSharing"
                                className="form-select travellar-input"
                                value={ele.roomSharing}
                                onChange={(e) => handleInputChange(index, e)}
                              >
                                <option value="">Select Room Sharing</option>
                                {travllerRoomSharing
                                  .filter((room) => room.persons !== 0)
                                  .map((room, idx) => (
                                    <option key={idx} value={room.roomType}>
                                      {room.roomType}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="boarding_point-0"
                                className="form-label travellar-lable"
                              >
                                Boarding Point:
                              </label>
                              <input
                                type="text"
                                id="boarding_point-0"
                                name="boarding_point"
                                className="form-control travellar-input c-b"
                                value={boardingData || ""}
                                onChange={(e) =>
                                  setBoardingData(e.target.value)
                                }
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3" key={index}>
                            <div className="col-md-12">
                              <label
                                htmlFor={`IdNumber-${index}`}
                                className="form-label travellar-lable"
                              >
                                ID Number
                              </label>
                              <input
                                type="text"
                                id={`IdNumber-${index}`}
                                placeholder="Ex.12121212154"
                                className="form-control travellar-input c-b"
                                name="IdNumber"
                                value={ele.IdNumber == "0" ? "" : ele.IdNumber}
                                onChange={(e) => handleInputChange(index, e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row g-3" key={index}>
                            <div className="col-md-12">
                              <label
                                htmlFor={`Document_Front-${index}`}
                                className="form-label travellar-lable"
                              >
                                Upload Document (Front):
                              </label>
                              <input
                                type="file"
                                id={`Document_Front-${index}`}
                                className="form-control travellar-input c-b"
                                name="Document_Front"
                                data-index={index}
                                onChange={handleFileChange}
                              />
                              {frontPreview && (
                                <div>
                                  <p>Preview (Front):</p>
                                  <img
                                    src={frontPreview}
                                    alt="Front Preview"
                                    width="100%"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="row g-3" key={index}>
                            <div className="col-md-12">
                              <label
                                htmlFor={`Document_Back-${index}`}
                                className="form-label travellar-lable"
                              >
                                Upload Document (Back):
                              </label>
                              <input
                                type="file"
                                id={`Document_Back-${index}`}
                                className="form-control travellar-input c-b"
                                name="Document_Back"
                                data-index={index}
                                onChange={handleFileChange2}
                              />
                              {backPreview && (
                                <div>
                                  <p>Preview (Back):</p>
                                  <img
                                    src={backPreview}
                                    alt="Back Preview"
                                    width="100%"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                      <div
                        style={{ textAlign: "center" }}
                        className="traveller-submit"
                      >
                        <button
                          type="button"
                          className="btn btn-warning traveller-submit-text px-5"
                          onClick={() => handleSubmit(index)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div style={{ textAlign: "center", marginBlock: "1.5rem" }}>
        <button className="btn  btnstyl" onClick={handleSkip}>
          Skip Now
        </button>
      </div>
    </div>
  );
};
export default Travellar;
