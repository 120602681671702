import React, { useState, useEffect } from "react";
import axios from "axios";
import "./backpacking.css";
import { useParams } from "react-router-dom";
import { Loader } from "../loader/Loader";
import { Link } from "react-router-dom";
import Label2Image from "../../assets/images/Label2image.png";
import { RedirectUrlAfterLogin } from "../repeatComponent/RedirectUrlAfterLogin";
import { useNavigate } from "react-router-dom";

function Backpacking() {
  const [cardsData, setCardsData] = useState<any>([]);
  const [load, setLoad] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);
  function formatTotalAmount(amount: any) {
    amount = parseFloat(amount);

    if (isNaN(amount)) {
      return "Invalid Amount";
    }

    return amount.toLocaleString("en-IN");
  }


  const fetchData = async () => {
    setLoad(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/admin/getBacgpackingList`)
      .then((res) => {
        if (res.data.success) {
          setCardsData(res.data.data);
        } else {
        }
      })
      .catch((err) => console.log(err, "error in catch"));
    setLoad(false);
  };

  return (
    <>
      <RedirectUrlAfterLogin />
      {load ? <Loader /> : ""}
      <div className="container my-3">
        <div className="row mt-4">
          <h1 className="text-center for_backpacking">
            <span className="pink-text">Backpacking </span>
            <span className="black-text">Trips </span>
          </h1>
          {cardsData.length !== 0 &&
            cardsData.map((card: any, index: number) => (
              <div
                key={index}
                className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2 mt-4"
              >
                  <div
                    className="card border-0 for_card_back mb-1"
                    onClick={() => {
                      const id = card._id;
                      const slugUrl = card?.title

                        ?.toLowerCase() // Convert to lowercase
                        .replace(/\s+/g, "-");
                      navigate(`/tripdetails/${slugUrl}`, {
                        state: id,
                      });
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${card?.tour_image?.[0]?.src}`}
                      className="card-img-top-back2 card-img2"
                      alt="Trip"
                    />
                    <div className="dayandnightt">
                      <p>
                        {card.nightorday}N/{card.nightorday + 1}D
                      </p>
                    </div>

                    <div className="for_visitdays mb-4">
                      <h5 className="for_h5">
                        {card.tourtype === "normal" ? "Group" : card.tourtype}
                      </h5>
                    </div>
                    <div className="for-sold-out2 mb-4">
                      <img src={Label2Image} className="img-fluid" />
                      <div className="LabelName">
                        {card?.tagLabelNames?.[0]
                          ? card?.tagLabelNames?.[0]
                          : null}
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="card-text">
                        <div className="main_para justify-content-between">
                          <h5 className="loremforfeatured">
                            {card.title.split(" ").slice(0, 7).join(" ")}
                            {card.title.split(" ").length > 7 ? "..." : ""}
                          </h5>
                          <p className="fetured_para">
                            {card.location.split(" ").slice(0, 4).join(" ")}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-baseline mt-2">
                          <p className=" startrate ">
                            {card.tourtype === "normal"
                              ? `Start from ₹${formatTotalAmount(
                                  card?.packageType_price
                                )}`
                              : "Customizable Price"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Backpacking;
