import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "../customer profile/Voucher.css";

import subtract from "../../assets/images/Subtract.jpg.png";
import Button from "react-bootstrap/Button";
import axios from "axios";
;
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../loader/Loader";
import Swal from "sweetalert2";
const Voucher = () => {
  const [vouchers, setVouchers] = useState([]);
  const [load, setLoad] = useState<boolean>(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchVouchers = async () => {
      setLoad(true);
      try {
        const url = `${process.env.REACT_APP_API_URL}/admin/get-all-coupon`;
        const response = await axios.get(url);
        setVouchers(response.data.data);
      } catch (error) {
        setError(true);
      }
      setLoad(false);
    };

    fetchVouchers();
  }, []);

  const copyToClipboard = (couponCode: any) => {
    navigator.clipboard
      .writeText(couponCode)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Copied to Clipboard",
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
        });
      });
  };
  return (
    <>
      {load ? <Loader /> : ""}
      <h1 className="myvoucher customer-border">My Vouchers</h1>
      <div className=" row container ">
        {vouchers.length > 0 ? (
          vouchers.map((item: any, index) => (
            <div
              className=" col-xl-6 col-lg-12 col-md-12 col-sm-12 p-0"
              key={index}
              style={{ justifyContent: "space-between" }}
            >
              <div className="smalldiv">
                <div className="pink">
                  <img src={subtract} alt="GlobalLogo" className="subtract" />
                  <div className="word">{item.coupon_code}</div>
                </div>
                <div className="sidepink">
                  <span className="lorem">{item.coupon_code} </span>
                  <span>
                    <Button
                      variant="warning"
                      className="yelobtn"
                      onClick={() => copyToClipboard(item.coupon_code)}
                    >
                      Copy
                    </Button>
                  </span>
                  <p className=" pinkline pt-2">
                    Save Upto ₹1500 on this Booking
                  </p>
                  <p className="loremone">{item.coupon_description}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No Vouchers available</p>
        )}
      </div>
    </>
  );
};

export default Voucher;
