import React from "react";
import Travelconcept from "../../assets/images/travel-concept-with-landmarks 1.png";
import Mohit from "../../assets/images/Ellipse 296 (1).png";
import Ankur from "../../assets/images/Ellipse 296 (2).png";
import Rahul from "../../assets/images/Ellipse 296 (3).png";
import Company1 from "../../assets/images/company1.png";
import Company2 from "../../assets/images/companies2.png";
import Company3 from "../../assets/images/companies3.png";
import Company4 from "../../assets/images/comapnies4.png";
import Company5 from "../../assets/images/companies5.png";
import Company6 from "../../assets/images/companies6.png";
import Company7 from "../../assets/images/companies7.png";
import Company8 from "../../assets/images/companies8.png";
import bgpink from "../../assets/images/bgpink.png";
import bgpinkchild from "../../assets/images/bgpinkchild.png";

import "./aboutus.css";
import { RedirectUrlAfterLogin } from "../repeatComponent/RedirectUrlAfterLogin";

function Aboutus() {

  return (
    <>
      <RedirectUrlAfterLogin />
      <div style={{ position: "relative", maxWidth: "100%", height: "auto" }}>
        <img
          src={Travelconcept}
          alt="About Us Page"

          className="bannerimg"
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            textAlign: "center",
            width: "90%",
          }}
        >
          <h2 style={{ fontSize: "4vw" }} className="for_aboutus">About Us</h2>
        </div>
      </div>
      <div className="container text-center" style={{ marginTop: "5vw" }}>
        <div className="row align-items-start ">
          <div className="col-lg-6 col-md-12">
            <div
              style={{ position: "relative", maxWidth: "100%", height: "auto" }}
            >
              <div
                style={{
                  position: "relative",
                  maxWidth: "90%",
                  height: "auto",
                }}
                className="for_mainbgpink"
              >
                <img
                  src={bgpink}
                  alt="Rectangle"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  className="for_bgpink"
                />
              </div>
              <div>
                <img
                  src={bgpinkchild}
                  alt="aboutusp"
                  style={{
                    position: "absolute",
                    top: "54.2%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    textAlign: "center",
                    width: "90%",
                  }}
                  className="for_bgpinkchild"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 h-100">
            <div>
              <h1
                style={{
                  textAlign: "justify",
                  fontWeight: "bold",
                }}
                className="for_loremhead"
              >
                "Discovering our core values and their origins defines our
                journey's essence."
              </h1>
              <p style={{ textAlign: "justify" }} className="for_loremtext">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
            {/* <div
              className="mt-5"
              style={{ display: "flex", justifyContent: "left" }}
            >
              <button
                type="button"
                className="btn btn-outline-dark mt-3 read_morebtn"
              >
                {" "}
                Read More
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div className="container">
        <h1 className="text-center my-4 font-weight-bold for_team pt-5">
          <span style={{ color: "rgb(247, 37, 72)" }}>Our</span> Team
        </h1>

        <div className="row">
          <div className="col-lg-3 col-md-6 mb-4 col-6">
            <div className="card text-center for-bgcard">
              <div className="card-body for-shadow">
                <img
                  src={Rahul}
                  className="img-fluid rounded-circle mb-2"
                  alt="Profile Image"
                />
                <h5 className="card-title mt-2">Vikas</h5>
                <p className="card-text forfounders">CEO, FOUNDER</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6">
            <div className="card text-center for-bgcard">
              <div className="card-body for-shadow">
                <img
                  src={Ankur}
                  className="img-fluid rounded-circle mb-2"
                  alt="Profile Image"
                />
                <h5 className="card-title mt-2">Vikas</h5>
                <p className="card-text forfounders">CEO, FOUNDER</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mb-4 col-6">
            <div className="card text-center for-bgcard">
              <div className="card-body for-shadow">
                <img
                  src={Mohit}
                  className="img-fluid rounded-circle mb-2"
                  alt="Profile Image"
                />
                <h5 className="card-title mt-2">Rahul</h5>
                <p className="card-text forfounders2">Head of Operations</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6">
            <div className="card text-center for-bgcard">
              <div className="card-body for-shadow for_shadow2">
                <img
                  src={Ankur}
                  className="img-fluid rounded-circle mb-2"
                  alt="Profile Image"
                />
                <h5 className="card-title mt-2">Vikas</h5>
                <p className="card-text forfounders">CEO, FOUNDER</p>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-center my-4 font-weight-bold for_team pt-5">
          <span style={{ color: "rgb(247, 37,72)" }}>Our</span> Partners
        </h1>

        <div className="row">
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company1}
                className="img-fluid mb-2 for_companyimg forsmallcopyimg"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company2}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company3}
                className="img-fluid mb-2 for_companyimg forsmallcopyimg"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company4}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company5}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company6}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company7}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 col-6 col-sm-6">
            <div className="forcomapniesbg text-center">
              <img
                src={Company8}
                className="img-fluid mb-2 for_companyimg2"
                alt="Profile Image"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aboutus;
