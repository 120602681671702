import React, { useState, useEffect } from "react";
import "./TravellerDetails.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { RiEditLine } from "react-icons/ri";
import { Loader } from "../../loader/Loader";

interface Traveller {
  Document_Front: any;
  Document_Back: any;
  name: string;
  DOB: string;
  age: string;
  mobile: string;
  email: string;
  city: string;
  Idproof: string;
  packageType: string;
  roomSharing: string;
  boarding_point: string;
  IdNumber: string;
  isOpen: boolean;
  state: string;
  country: string;
  pincode: number;
}
interface State {
  name: string;
  shortCode: string | null | undefined;
  stateCode: string;
  stateName: string;
}
const TravellerDetails = (props: any) => {
  let boardingPoint = props.travllerDeatils.travel_boarding_point.boarding;
  let packageType = props?.travllerDeatils?.travel_packageType_data;
  let roomSharing = props.travllerDeatils.travel_roomSharing_data;
  const [travellers, setTravellers] = useState<Traveller[]>([]);
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [load, setLoad] = useState<boolean>(true);
  const [countries, setCountries] = useState<any[]>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [states, setStates] = useState<State[]>([]);
  const [selectedStateCode, setSelectedStateCode] = useState("");
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [activeFieldIndex, setActiveFieldIndex] = useState(-1);
  const [editMode, setEditMode] = useState(false);
  const location = useLocation();
  const { id } = location.state || {};
  const [file, setFile] = useState<any>();
  const [file2, setFile2] = useState<any>();
  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/userTourDetails/alltourUserDetailsById?applyTour_id=${id}`
        );
        if (response.data.success) {
          setTravellers(response.data.data[0].travelers);
        } else {
          console.error("Failed to fetch data:", response.data.error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoad(false);
    };

    fetchData();
  }, [id, fetchTrigger]);

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (editMode) {
      const { name, value } = event.target;
      const newTravellers = [...travellers];
      newTravellers[index] = {
        ...newTravellers[index],
        [name]: value,
      };


      if (name === "boarding point") {
        newTravellers[index].boarding_point = value;
      }
      if (name === "DOB") {
        const dob = new Date(value);
        const today = new Date();
        const age = (today.getFullYear() - dob.getFullYear()).toString();
        newTravellers[index].age = age;
      }
      setTravellers(newTravellers);
    }
  };
  const toggleAccordion = (index: number) => {
    const newTravellers = travellers.map((traveller, i) => {
      if (index === i) {
        return {
          ...traveller,
          isOpen: !traveller.isOpen,
        };
      } else {
        return { ...traveller, isOpen: false };
      }
    });
    setTravellers(newTravellers);
  };
  const handleSubmit = async (index: any) => {
    setLoad(true);
    try {
      // travellers[index].Document_Front = file;
      // travellers[index].Document_Back = file2;
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/setUserTourDetails?applyTour_id=${id}`,
        { travelers: travellers }
      );
      if (response.data.success) {
        setFetchTrigger(!fetchTrigger);
      } else {
        console.error("Failed to submit data:", response.data.error);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    setLoad(false);
  };
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getAll_countriesList`
        );
        setCountries(response?.data?.Data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);
  useEffect(() => {
    const fetchStatesByCountryCode = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getStateByCountryCode`,
          { countryShortCode: selectedCountryCode }
        );
        setStates(response.data.getData);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    if (selectedCountryCode) {
      fetchStatesByCountryCode();
    }
  }, [selectedCountryCode]);
  const handleCountryChange = (event: any) => {
    const selectedValue = event.target.value;
    const selectedLabel = event.target.options[event.target.selectedIndex].text;
    let countryState = travellers.map((ele: any) => {
      return { ...ele, country: selectedLabel };
    });
    setTravellers(countryState);
    setSelectedCountryCode(selectedValue);
  };
  const handleStateChange = (event: any) => {
    const selectedValue2 = event.target.value;
    const selectedLabel2 =
      event.target.options[event.target.selectedIndex].text;
    let stateCode = travellers.map((ele: any) => {
      return { ...ele, state: selectedLabel2 };
    });
    setTravellers(stateCode);
    setSelectedStateCode(selectedValue2);
  };
  const handleIdChange = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (editMode) {
      const { value } = event.target;
      setTravellers((prevState) => {
        const updatedTravellers = [...prevState];
        updatedTravellers[index] = {
          ...updatedTravellers[index],
          Idproof: value,
        };
        return updatedTravellers;
      });
    }
  };
  const toggleEditMode = (fieldIndex: number) => {
    setEditMode(!editMode);
    setActiveFieldIndex(fieldIndex);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const form = new FormData();
      form.append("uploadFile", event.target.files[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload-file`,
        form
      );
      travellers[index].Document_Front = response.data.file_data[0];
    }
  };
  const handleFileChange2 = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const form = new FormData();
      form.append("uploadFile", event.target.files[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload-file`,
        form
      );
      travellers[index].Document_Back = response.data.file_data[0];
    }
  };

  return (
    <>
      {load ? <Loader /> : ""}
      <div>
        {travellers.map((traveller, index) => (
          <div key={index} className="container traveller-form-size mb-4">
            <div
              className="accordion accordion-style accordion-flush"
              id={`accordionFlushExample${index}`}
            >
              <div className="accordion-item">
                <h1
                  className={`traveller-H accordion-header accordion-button ${
                    traveller.isOpen ? "" : "collapsed"
                  }`}
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapse${index}`}
                  aria-expanded={traveller.isOpen ? "true" : "false"}
                  aria-controls={`flush-collapse${index}`}
                  onClick={() => toggleAccordion(index)}
                >
                  Traveller {index + 1} &nbsp;
                  {traveller.name ? (
                    <span> - {traveller.name}</span>
                  ) : (
                    "Details"
                  )}
                  <span className="px-3 text-danger">
                    {editMode && activeFieldIndex === index ? (
                      <span
                        className="traveller-submit-text"
                        onClick={() => {
                          toggleEditMode(index);
                          handleSubmit(index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Save
                      </span>
                    ) : (
                      <>
                        <RiEditLine />
                        <span
                          className="traveller-submit-text"
                          style={{ cursor: "pointer" }}
                          onClick={() => toggleEditMode(index)}
                        >
                          Edit
                        </span>
                      </>
                    )}
                  </span>
                </h1>
                <div
                  id={`flush-collapse${index}`}
                  className={`accordion-collapse collapse ${
                    traveller.isOpen ? "show" : ""
                  }`}
                  data-bs-parent={`#accordionFlushExample${index}`}
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-12 ">
                        <form className="row g-3">
                          <div className="col-md-3">
                            <div className="row g-3">
                              <div className="col-md-12">
                                <label className="form-label traveller-label">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control travellar-input c-b"
                                  name="name"
                                  value={traveller.name}
                                  disabled={!editMode}
                                  onChange={(e) => handleInputChange(index, e)}
                                  placeholder="Enter Your Name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row g-3">
                              <div className="col-md-12">
                                <label className="form-label travellar-lable">
                                  Date Of Birth
                                </label>
                                <input
                                  type="date"
                                  className="form-control travellar-input"
                                  name="DOB"
                                  value={traveller.DOB}
                                  onChange={(e) => handleInputChange(index, e)}
                                  disabled={!editMode}
                                  placeholder="Please enter your DOB"
                                  autoSave="off"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row g-3">
                              <div className="col-md-12">
                                <label className="form-label travellar-lable">
                                  Age
                                </label>
                                <input
                                  type="tel"
                                  className="form-control travellar-input"
                                  readOnly
                                  value={traveller?.age}
                                  disabled={!editMode}
                                  placeholder="Calculated Age"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row g-3">
                              <div className="col-md-12">
                                <label className="form-label travellar-lable">
                                  Mobile Number
                                </label>
                                <input
                                  type="tel"
                                  pattern="[0-9]*"
                                  inputMode="numeric"
                                  className="form-control travellar-input"
                                  name="mobile"
                                  value={traveller.mobile}
                                  disabled={!editMode}
                                  placeholder="Ex. 9754699505"
                                  onChange={(e) => handleInputChange(index, e)}
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ); // Allow only numbers
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row g-3">
                              <div className="col-md-12">
                                <label className="form-label travellar-lable">
                                  Email Id
                                </label>
                                <input
                                  type="email"
                                  className="form-control travellar-input"
                                  name="email"
                                  value={traveller.email}
                                  disabled={!editMode}
                                  onChange={(e) => handleInputChange(index, e)}
                                  placeholder="john234@gmail.com"
                                />
                              </div>
                            </div>
                          </div>
                          {editMode ? (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Country
                                  </label>
                                  <select
                                    id="countrySelect"
                                    className="form-select travellar-input"
                                    name="country"
                                    value={traveller.country}
                                    onChange={handleCountryChange}
                                  >
                                    <option value="">
                                      {selectedCountryCode
                                        ? countries.find(
                                            (country) =>
                                              country.countryShortCode ===
                                              selectedCountryCode
                                          ).countryName
                                        : traveller.country ||
                                          "Select a country"}
                                    </option>
                                    {countries?.map((country) => (
                                      <option
                                        key={country.countryShortCode}
                                        value={country.countryShortCode}
                                      >
                                        {country.countryName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Country
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control travellar-input"
                                    name="country"
                                    value={traveller.country}
                                    disabled={!editMode}
                                    readOnly={!editMode}
                                    placeholder="Country"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {editMode ? (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    State
                                  </label>
                                  <select
                                    id="State"
                                    className="form-select travellar-input"
                                    name="state"
                                    value={traveller.state}
                                    onChange={handleStateChange}
                                  >
                                    <option value="">
                                      {traveller.state || "Select a State"}
                                    </option>
                                    {states?.map((state) => (
                                      <option
                                        key={state.shortCode}
                                        value={state.stateCode}
                                      >
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    State
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control travellar-input"
                                    name="state"
                                    value={traveller.state}
                                    readOnly={!editMode}
                                    placeholder="State"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-md-3">
                            <div className="row g-3">
                              <div className="col-md-12">
                                <label className="form-label travellar-lable">
                                  Pin Code
                                </label>
                                {/* <input
                                type="text"
                                className="form-control travellar-input"
                                name="gender"
                                value={traveller.pincode}
                                disabled={!editMode}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Ex. 450001"
                              /> */}

                                <input
                                  type="tel"
                                  pattern="[0-9]*"
                                  className="form-control travellar-input"
                                  name="pincode"
                                  value={traveller.pincode}
                                  disabled={!editMode}
                                  placeholder="Ex. 450001"
                                  onChange={(e) => handleInputChange(index, e)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row g-3">
                              <div className="col-md-12">
                                <label className="form-label travellar-lable">
                                  City
                                </label>
                                <input
                                  type="text"
                                  className="form-control travellar-input"
                                  name="city"
                                  value={traveller.city}
                                  disabled={!editMode}
                                  onChange={(e) => handleInputChange(index, e)}
                                  placeholder="City"
                                />
                              </div>
                            </div>
                          </div>
                          {editMode ? (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div key={index} className="col-md-12">
                                  <label className="form-label traveller-label">
                                    ID Proof
                                  </label>

                                  <select
                                    id={`idProof${index}`}
                                    className="form-select traveller-input"
                                    name="Idproof"
                                    // aria-placeholder="Select ID Proof"
                                    value={traveller.Idproof}
                                    onChange={(e) => handleIdChange(index, e)}
                                  >
                                    <option value="Adharcard">
                                      Aadhar Card
                                    </option>
                                    <option value="pancard">Pan card</option>
                                    <option value="drivinglicence">
                                      Driving Licence
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    ID Proof
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control travellar-input"
                                    name="Idproof"
                                    value={traveller.Idproof}
                                    disabled={!editMode}
                                    readOnly={!editMode}
                                    placeholder="ID proof"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {editMode ? (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Package Type
                                  </label>
                                  <select
                                    id="boardinpoint"
                                    className="form-select traveller-input"
                                    name="packageType"
                                    value={traveller.packageType}
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                  >
                                    <option value="">
                                      Select Package Type
                                    </option>
                                    {Array.isArray(packageType) &&
                                      packageType.map((boarding: any) => (
                                        <option key={boarding.id}>
                                          {boarding?.packType}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Package Type
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control travellar-input"
                                    name="packageType"
                                    value={traveller.packageType}
                                    disabled={!editMode}
                                    readOnly={!editMode}
                                    placeholder="PackageType"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {editMode ? (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Room Sharing
                                  </label>
                                  <select
                                    id="boardinpoint"
                                    className="form-select traveller-input"
                                    name="roomSharing"
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                    value={traveller.roomSharing}
                                  >
                                    <option value="">
                                      Select Room Sharing
                                    </option>
                                    {Array.isArray(roomSharing) &&
                                      roomSharing.map((boarding: any) => (
                                        <option key={boarding.id}>
                                          {boarding?.roomType}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Room Sharing
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control travellar-input"
                                    name="roomSharing"
                                    value={traveller.roomSharing}
                                    disabled={!editMode}
                                    readOnly={!editMode}
                                    placeholder="roomSharing"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {editMode ? (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Boarding Point
                                  </label>
                                  <select
                                    id="boardinpoint"
                                    className="form-select traveller-input"
                                    value={traveller.boarding_point}
                                    onChange={(e) =>
                                      handleInputChange(index, e)
                                    }
                                    name="boarding point"
                                  >
                                    <option value="">
                                      Select boarding Point
                                    </option>
                                    <option>{boardingPoint}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Boarding Point
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control travellar-input"
                                    name="boarding point"
                                    value={traveller.boarding_point}
                                    disabled={!editMode}
                                    readOnly={!editMode}
                                    placeholder="boarding point"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-md-3">
                            <div className="row g-3">
                              <div className="col-md-12">
                                <label className="form-label traveller-label">
                                  ID Number
                                </label>
                                <input
                                  type="tel"
                                  className="form-control travellar-input"
                                  placeholder="Ex.1212121215"
                                  name="IdNumber"
                                  value={
                                    traveller.IdNumber == "0"
                                      ? ""
                                      : traveller.IdNumber
                                  }
                                  disabled={!editMode}
                                  onChange={(e) => handleInputChange(index, e)}
                                />
                              </div>
                            </div>
                          </div>
                          {editMode ? (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Document Front
                                  </label>
                                  <input
                                    type="file" // Changed from 'tel' to 'file' for image upload
                                    className="form-control travellar-input"
                                    accept="image/*" // Restrict to image files only
                                    onChange={(event) =>
                                      handleFileChange(event, index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Document Front
                                  </label>
                                  <img
                                    src={
                                      `${process.env.REACT_APP_API_URL}/${traveller?.Document_Front[0]?.src}` ||
                                      "null"
                                    }
                                    alt="Document Front Preview"
                                    className="img-fluid travellar-input" // Class name for styling
                                    style={{ maxWidth: "100%" }} // Ensure the image fits within the container
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {editMode ? (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Document Back
                                  </label>
                                  <input
                                    type="file" // Changed from 'tel' to 'file' for image upload
                                    className="form-control travellar-input"
                                    accept="image/*" // Restrict to image files only
                                    onChange={(event) =>
                                      handleFileChange2(event, index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-3">
                              <div className="row g-3">
                                <div className="col-md-12">
                                  <label className="form-label traveller-label">
                                    Document Back
                                  </label>
                                  <img
                                    src={
                                      `${process.env.REACT_APP_API_URL}/${traveller?.Document_Back[0]?.src}` ||
                                      "null"
                                    } // `imageSrc` should be the state variable holding the image URL
                                    alt="Document Back Preview"
                                    className="img-fluid travellar-input" // Class name for styling
                                    style={{ maxWidth: "100%" }} // Ensure the image fits within the container
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </form>
                        <div
                          style={{ textAlign: "center" }}
                          className="traveller-submit"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default TravellerDetails;
